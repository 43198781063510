import React from 'react'

const Bloomberg = ({ className, width = 180, color = '#000', ...props }) => {
  return (
    <svg
      width={width}
      viewBox='115 -9.6 257.8 47.6'
      className={className}
      {...props}>
      <g>
        <path
          fill={color}
          d='M115-9.6h18.9c3.6,0,6.3,0.9,8.3,2.4c2.2,1.8,3.4,4.4,3.4,7.6c0,3.7-1.5,6-4.5,7.4V8c4,1.3,6.3,4.9,6.3,9.2
		c0,4.1-1.6,7.3-4.2,9.3c-2.2,1.6-5,2.3-8.8,2.3H115V-9.6z M132,5.4c2,0,3.5-1.1,3.5-3.4c0-2.2-1.5-3.3-3.6-3.3h-6.5v6.6H132z
		 M132.9,20.3c2.3,0,4-1.4,4-3.9c0-2.7-1.8-3.9-4-3.9h-7.4v7.9H132.9z'
        />
        <path fill={color} d='M149.1-9.6h9.9v38.5h-9.9V-9.6z' />
        <path
          fill={color}
          d='M161,15.2c0-8.7,5.6-14.5,14.3-14.5s14.2,5.9,14.2,14.5c0,8.7-5.5,14.5-14.2,14.5
		C166.6,29.7,161,23.9,161,15.2z M179.6,15.2c0-4.8-1.5-7.8-4.5-7.8s-4.4,3-4.4,7.8c0,4.8,1.4,7.9,4.4,7.9
		C178.1,23,179.6,20,179.6,15.2z'
        />
        <path
          fill={color}
          d='M190.6,15.2c0-8.7,5.6-14.5,14.3-14.5S219,6.5,219,15.2c0,8.7-5.5,14.5-14.2,14.5
		C196.2,29.7,190.6,23.9,190.6,15.2z M209.2,15.2c0-4.8-1.5-7.8-4.5-7.8c-3,0-4.4,3-4.4,7.8c0,4.8,1.4,7.9,4.4,7.9
		C207.7,23,209.2,20,209.2,15.2z'
        />
        <path
          fill={color}
          d='M221,1.4h9.2v4.1h0.2c1.8-3.2,4.3-4.9,8.3-4.9c3.5,0,6.1,1.7,7.4,4.7h0.2c2.2-3.6,5.1-4.7,8.5-4.7
		c6.4,0,9.1,4.7,9.1,11v17.2h-9.6V13.3c0-2.8-0.9-4.6-3.3-4.6c-2.4,0-3.6,2.2-3.6,5.2v14.9h-9.6V13.3c0-2.8-0.9-4.6-3.3-4.6
		c-2.4,0-3.6,2.2-3.6,5.2v14.9H221C221,28.8,221,1.4,221,1.4z'
        />
        <path
          fill={color}
          d='M276,25.2h-0.1v3.6h-9.2V-9.6h9.6V4.2h0.2c1.8-2.4,4.3-3.7,7.8-3.7c6.9,0,10.9,6.2,10.9,14.5
		c0,9.3-4.3,14.7-11.5,14.7C280.7,29.7,277.6,28.3,276,25.2z M285.3,14.9c0-4.4-1.6-7.3-4.5-7.3c-3,0-4.8,2.9-4.8,7.3
		c0,4.4,1.9,7.3,4.8,7.3S285.3,19.4,285.3,14.9z'
        />
        <path
          fill={color}
          d='M296.3,15.1c0-8.6,5.7-14.5,13.9-14.5c4.1,0,7.2,1.3,9.6,3.6c3.2,3.1,4.6,7.9,4.5,13.5h-18.4
		c0.4,3.4,2,5.2,4.9,5.2c1.7,0,3.2-0.8,3.8-2.6h9.3c-1.6,6.3-6.5,9.4-13.4,9.4C302,29.6,296.3,23.8,296.3,15.1z M305.9,12.2h8.8
		c-0.2-3.3-2-5-4.3-5C307.7,7.1,306.2,9,305.9,12.2z'
        />
        <path
          fill={color}
          d='M335.2,6.1h0.2c2-3.8,4.6-5.3,7.9-5.3c0.8,0,1.3,0.1,1.6,0.3v8.4h-0.2c-5.7-1-9,1.7-9,7.9v11.5H326V1.4h9.2
		V6.1z'
        />
        <path
          fill={color}
          d='M345.9,28.9h9.3c0.4,1.2,1.6,2.3,3.9,2.3c3.1,0,4.2-1.9,4.2-4.8v-2h-0.2c-1.5,1.8-3.6,3.1-6.6,3.1
		c-5.9,0-11.1-4.4-11.1-13c0-7.9,4.2-14,10.6-14c3.7,0,5.9,1.5,7.4,3.8h0.2v-3h9.2v23.8c0,4.4-1.5,7.8-3.8,9.8c-2.4,2.1-5.8,3-9.7,3
		C351.9,38,346.8,35,345.9,28.9z M363.7,14.1c0-3.4-1.6-6.2-4.4-6.2c-2.7,0-4.4,2.4-4.4,6.2c0,3.7,1.7,6.3,4.4,6.3
		C362,20.4,363.7,17.7,363.7,14.1z'
        />
      </g>
    </svg>
  )
}
export default Bloomberg
