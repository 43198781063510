import React from 'react'

const SebpoLogo = ({ width = 106, ...props }) => (
  <svg viewBox='0 0 100 106' width={width} {...props}>
    <path
      fill='#fff'
      d='M31.88 9.85l-.92 4.71-.86 6.77-.23 7.88.3 6.31 1.18 8.69 2.27 8.2 3.07 7.39 3.6 5.87 4.99 6.71 6.51 6.12 7.19 5.02 6.24 3.49 7.72 3.39 11.42 3.31 6.64.94-16.28-6.25-8.17-4.74-7.99-5.45-9.29-9.18-5.78-7.94-4.43-8.05-3.38-8.86-2.18-8.8-1.31-8.47-.39-7.05z'
    />
    <path
      fill='#fff'
      d='M39.8 7.7l-1.19 9.01-.32 13.16 1.12 9.6 3.24 11.61 5.06 9.94 4.68 6.46 5.47 5.44 6.88 5.44 10.15 5.76 12.97 4.85 11.38 2.48 1.32-.18-11.82-4.11-7.33-3.32-7.05-3.98-8.33-6-5.33-4.89-6.08-7.35-5.37-8.24-3.81-8.47-2.58-8.48-1.85-8.96-.94-11.34z'
    />
    <path
      d='M26.2 105.12L1.93 64.58l1.49-.89 24.27 40.54-1.49.89z'
      fill='#fab162'
    />
    <path
      fill='#f9b062'
      d='M98.63 61.68l-.78 1.56-42.02-21.02.39-.78-.64-.59 17.75-19.53 1.29 1.17-16.98 18.69 40.99 20.5z'
    />
    <path
      d='M42.62 68.39c-9.7-12.7-13.66-29.11-11.86-48.91h-5.67L0 62.96l25.09 43.46h50.2l7.79-13.49c-11.73-2.87-28.98-9.49-40.46-24.54zm-15.68 36.29L2.68 64.13l42.02 21-17.76 19.55z'
      fill='url(#sebpo-gradient1)'
    />
    <path
      d='M85.98 87.89l-2.51 4.35C52.44 80.99 39.9 59.71 34.91 42.9c-2.54-8.56-3.41-16.76-3.56-23.41h7.42c-1.03 18.22 3.03 33.43 12.15 45.36 9.82 12.87 23.87 19.57 35.06 23.04zm-10.69-68.4l25.09 43.47L86.67 86.7c-14.46-6.11-25.75-15.04-33.72-26.66C43.54 46.3 40.5 31.11 39.8 19.49h35.49zm22.96 42.97L73.97 21.91 56.22 41.45l42.03 21.01z'
      fill='url(#sebpo-gradient2)'
    />
    <path
      fill='#f9b062'
      d='M95.87 95.91h-.02c-.35-.01-34.89-2.11-53.89-27-12.27-16.07-15.51-37.96-9.63-65.04.05-.22.26-.34.49-.32.22.05.37.26.33.49-.03.18-3.46 18.42 2.56 38.65 5.55 18.65 20.51 42.88 60.25 52.4.21.05.35.25.32.47-.02.2-.2.35-.41.35M31.38 13.95c-3.11 22.3.66 40.58 11.25 54.44 13.99 18.32 36.51 24.14 47.23 25.93-35.74-10.5-49.62-33.5-54.95-51.42-3.31-11.12-3.79-21.64-3.53-28.95'
    />
    <path
      fill='#f9b062'
      d='M104.16 92.36h-.02c-.34-.02-34.89-2.12-53.89-27.01C37.98 49.28 34.74 27.4 40.62.32c.05-.22.27-.34.49-.32.22.04.37.26.32.49-.06.32-6.04 32.46 12.21 59.08 10.8 15.75 27.82 26.51 50.61 31.96.21.05.35.25.31.47a.4.4 0 01-.4.36M39.68 10.27c-3.14 22.36.62 40.68 11.23 54.58 13.97 18.31 36.48 24.14 47.21 25.93-20.03-5.88-35.2-16.2-45.16-30.73-12.2-17.8-13.69-38.02-13.28-49.78'
    />
  </svg>
)

export default SebpoLogo
