import React from 'react'

const SelectorIcon = ({
  size = 20,
  stroke = 'currentColor',
  strokeWidth = '0',
  fill = '#fff',
  ...props
}) => (
  <svg
    stroke={stroke}
    fill={fill}
    strokeWidth={strokeWidth}
    viewBox='0 0 20 20'
    width={size}
    {...props}>
    <path
      d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'></path>
  </svg>
)
export default SelectorIcon
