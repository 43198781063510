import React from 'react'

const CrsLogo = ({ width, lightcolor = '#fff' }) => (
  <svg viewBox='0 0 116 39' width={width}>
    <path
      d='M35.7278 0L18.0807 30.0783H16.117L10.4302 20.3545H0L10.8127 38.996H23.4105L46.1325 0H35.7278Z'
      fill='url(#crs-gradient)'
    />
    <path
      d='M33.7133 38.9961L29.531 31.8418L25.3232 38.9961H33.7133Z'
      fill={lightcolor}
    />
    <path
      d='M28.5108 8.89249L33.7132 0H10.9147L0 18.6415H10.4302L16.117 8.89249H28.5108Z'
      fill={lightcolor}
    />
    <path
      d='M101.894 28.5172C101.894 27.0401 101.366 25.9105 100.31 25.1285C99.2548 24.3291 97.3548 23.495 94.6105 22.6261C91.8661 21.7398 89.6935 20.8709 88.0926 20.0194C83.7297 17.6908 81.5483 14.554 81.5483 10.6092C81.5483 8.55865 82.1288 6.73396 83.2899 5.13519C84.4686 3.51904 86.1486 2.25914 88.3301 1.35548C90.5291 0.451827 92.992 0 95.7188 0C98.4631 0 100.908 0.495272 103.055 1.48582C105.201 2.45898 106.863 3.84053 108.042 5.63046C109.238 7.42039 109.836 9.45361 109.836 11.7301H101.92C101.92 9.99233 101.366 8.64554 100.258 7.68975C99.1492 6.71658 97.5923 6.23 95.5868 6.23C93.6517 6.23 92.1476 6.63838 91.0744 7.45515C90.0013 8.25453 89.4648 9.31459 89.4648 10.6353C89.4648 11.8691 90.0893 12.9031 91.3383 13.7373C92.605 14.5714 94.4609 15.3534 96.9062 16.0833C101.41 17.4214 104.691 19.081 106.749 21.0621C108.807 23.0432 109.836 25.5109 109.836 28.4651C109.836 31.7495 108.579 34.3302 106.063 36.207C103.547 38.0664 100.161 38.9962 95.9035 38.9962C92.948 38.9962 90.2564 38.4661 87.8287 37.4061C85.401 36.3286 83.545 34.8602 82.2608 33.0008C80.9942 31.1413 80.3608 28.9864 80.3608 26.5362H88.3037C88.3037 30.7242 90.837 32.8183 95.9035 32.8183C97.7858 32.8183 99.2548 32.4447 100.31 31.6974C101.366 30.9328 101.894 29.8727 101.894 28.5172Z'
      fill={lightcolor}
    />
    <path
      d='M62.4846 24.7244H55.9797V39.0002H47.8145V0.00292969H62.5391C67.2204 0.00292969 70.8313 1.02964 73.3716 3.08307C75.9119 5.1365 77.182 8.03808 77.182 11.7878C77.182 14.4484 76.5923 16.6714 75.4129 18.457C74.2516 20.2247 72.4825 21.6353 70.1055 22.6888L78.679 38.6252V39.0002H69.915L62.4846 24.7244ZM55.9797 18.2159H62.5663C64.6166 18.2159 66.2043 17.7071 67.3293 16.6893C68.4543 15.6536 69.0168 14.2341 69.0168 12.4306C69.0168 10.5915 68.4815 9.14515 67.411 8.09165C66.3586 7.03815 64.7346 6.5114 62.5391 6.5114H55.9797V18.2159Z'
      fill={lightcolor}
    />
    <path
      d='M111.264 36.6528C111.264 36.2316 111.367 35.8406 111.574 35.4799C111.783 35.1192 112.071 34.8356 112.438 34.6292C112.807 34.4207 113.205 34.3164 113.632 34.3164C114.059 34.3164 114.456 34.4207 114.823 34.6292C115.192 34.8356 115.48 35.1192 115.687 35.4799C115.896 35.8406 116 36.2316 116 36.6528C116 37.0782 115.895 37.4722 115.683 37.8351C115.472 38.1958 115.183 38.4794 114.816 38.6858C114.451 38.8922 114.057 38.9954 113.632 38.9954C113.203 38.9954 112.805 38.8912 112.438 38.6827C112.073 38.4741 111.786 38.1895 111.577 37.8288C111.368 37.4681 111.264 37.0761 111.264 36.6528ZM115.59 36.6528C115.59 36.3067 115.506 35.9855 115.34 35.6895C115.173 35.3913 114.937 35.1536 114.633 34.9763C114.328 34.797 113.994 34.7074 113.632 34.7074C113.278 34.7074 112.949 34.7929 112.645 34.9638C112.342 35.1348 112.104 35.3704 111.931 35.6707C111.757 35.971 111.671 36.2983 111.671 36.6528C111.671 37.0073 111.756 37.3346 111.927 37.6349C112.101 37.9351 112.339 38.1728 112.641 38.348C112.946 38.5211 113.276 38.6076 113.632 38.6076C113.988 38.6076 114.318 38.519 114.623 38.3417C114.929 38.1645 115.167 37.9258 115.336 37.6255C115.505 37.3252 115.59 37.001 115.59 36.6528ZM113.152 36.8905V37.9445H112.648V35.286H113.585C113.921 35.286 114.186 35.3579 114.379 35.5018C114.573 35.6436 114.669 35.8458 114.669 36.1086C114.669 36.3421 114.544 36.5235 114.292 36.6528C114.428 36.7174 114.522 36.8081 114.576 36.9249C114.631 37.0396 114.659 37.1824 114.659 37.3534C114.659 37.5244 114.663 37.6453 114.669 37.7162C114.678 37.7871 114.694 37.8465 114.716 37.8945V37.9445H114.199C114.17 37.8736 114.156 37.6714 114.156 37.3378C114.156 37.1793 114.119 37.0656 114.046 36.9968C113.972 36.9259 113.85 36.8905 113.679 36.8905H113.152ZM113.152 36.4714H113.605C113.77 36.4714 113.904 36.438 114.009 36.3713C114.116 36.3046 114.169 36.217 114.169 36.1086C114.169 35.9626 114.129 35.8594 114.049 35.7989C113.971 35.7364 113.829 35.7041 113.622 35.702H113.152V36.4714Z'
      fill={lightcolor}
    />
  </svg>
)
export default CrsLogo
